let isInitialized = false;
let intersectionObserver = null;
let mutationObserver = null;

function domFindAllImgWithLazySrc () {
  const out = [];
  const allImages = document.getElementsByTagName('img');
  for (let i = 0; i < allImages.length; ++i) {
    const img = allImages[i];
    const lazySrc = img.getAttribute('data-src');
    const currSrc = img.getAttribute('src');
    if (lazySrc && lazySrc !== currSrc) {
      out.push(img);
    }
  }
  return out;
}

function loadImage (img) {
  setTimeout(() => {
    const lazySrc = img.getAttribute('data-src');
    const currSrc = img.getAttribute('src');
    if (lazySrc && lazySrc !== currSrc) {
      img.setAttribute('src', lazySrc);
      img.removeAttribute('data-src');
    }
  });
}

function onIntersectionCallback (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      loadImage(entry.target);
      observer.unobserve(entry.target);
    }
  });
}

function loadLazyImages () {
  if (!isInitialized) {
    console.error("Attempting to call loadLazyImages before it's initialize.");
    return;
  }
  const targetElements = domFindAllImgWithLazySrc();
  targetElements.forEach(elem => {
    intersectionObserver.observe(elem);
  });
}

function initLazyImageLoader () {
  if (isInitialized) {
    console.error('Attempting to double initialize lazy loader');
    return;
  }

  isInitialized = true;
  const container = document.documentElement || document.body;
  // When document changes, mutationObserver will attach any new images
  // it finds to the IntersectionObserver.
  mutationObserver = new MutationObserver(loadLazyImages);
  mutationObserver.observe(
    container,
    { attributes: true, childList: true, subtree: true });
  intersectionObserver = new IntersectionObserver(
    onIntersectionCallback,
    { rootMargin: '600px' });
}

export { initLazyImageLoader };
