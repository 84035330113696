import Chart from 'chart.js/auto';
import { fetchVoteBiasData } from '../bias-graphs/fetch-bias-data';
import { getDebugValue } from '../../util';
import { formatDate } from './dateUtils';

const LINE_CHART_THICKNESS = 3;
const DEFAULT_CHART_TYPE = getDebugValue() === '2'
  ? 'line'
  : 'bar';

const defaultOptions = {
  conservative: {
    backgroundColor: [
      '#c1250f'
    ],
    borderColor: [
      '#c1250f'
    ],
    borderWidth: LINE_CHART_THICKNESS,
    pointRadius: 0
  },
  liberal: {
    backgroundColor: [
      '#3230cc'
    ],
    borderColor: [
      '#3230cc'
    ],
    borderWidth: LINE_CHART_THICKNESS,
    pointRadius: 0
  },
  swing: {
    backgroundColor: [
      '#7a36c3'
    ],
    borderColor: [
      '#7a36c3'
    ],
    borderWidth: LINE_CHART_THICKNESS,
    pointRadius: 0
  }
};

/**
 * Determines what date range selection corresponds to what JSON data source
 */
function getGraphType (range) {
  switch (range.toUpperCase()) {
    case '1D':
      return 'daily_bias_graph';
    case '1W':
      return 'weekly_bias_graph';
    case '1M':
      return 'monthly_bias_graph';
    case '3M':
      return 'quarterly_bias_graph';
    case 'ALL':
      return 'alltime_bias_graph';
    case '1Y':
    default:
      return 'annual_bias_graph';
  }
}

/**
 * Puts a click listener on the date range selections
 */
function setRangeListener (chart, selector, platformName) {
  const domRange = document.querySelectorAll(selector);

  domRange.forEach(dom => {
    // eslint-disable-next-line no-underscore-dangle
    const clickListeners = (dom && dom._events && dom._events.click) || false;

    if (clickListeners) {
      console.error('Range listeners already set');
      return;
    }

    dom.addEventListener('click', async e => {
      const option = e.currentTarget;

      for (const child of option.parentNode.children) {
        child.classList.remove('time-range-option-active');
      }

      option.classList.add('time-range-option-active');

      const range = e.currentTarget.innerText;

      try {
        await updateBarChart(chart, range, platformName);
      } catch (err) {
        console.warn("Data not available for this range.  It's probably too early.", err);
      }
    });
  });
}

/**
 * Creates a multiple dataset chart
 */
export async function createBarChart ({ selectors, width, height, default_range, platformName, type = DEFAULT_CHART_TYPE }) {
  const ctx = document.querySelector(selectors.canvas).getContext('2d');
  const chart = new Chart(ctx, {
    width,
    height,
    type,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          enabled: true
        }
      }// ,
      // elements: {
      //   line: {
      //     tension: 0 // disables bezier curves
      //   }
      // },
      // animation: {
      //   duration: 0 // general animation time
      // },
      // hover: {
      //   animationDuration: 0 // duration of animations when hovering an item
      // },
      // responsiveAnimationDuration: 0 // animation duration after a resize
    },
    scales: {
      x: {
        type: 'time', // Set x-axis to time
        stacked: false // Optional: stack bars
      },
      y: {
        beginAtZero: true
      }
    }
  });

  setRangeListener(chart, selectors.range, platformName);

  chart.update();

  return updateBarChart(chart, default_range, platformName);
}

/**
 *
 */
function getShortHour (dateStr) {
  const { month, day, amOrPmHours, amOrPm } = formatDate(dateStr);
  return `${month}-${day} ${amOrPmHours}${amOrPm}`;
}

/**
 *
 */
function getShortDate (dateStr) {
  const { month, day } = formatDate(dateStr);
  return `${month}-${day}`;
}

/**
 * Updates the data on a chart
 */
export async function updateBarChart (chart, default_range, platformName) {
  const data = await fetchVoteBiasData(getGraphType(default_range), platformName);

  const generateLabel = default_range === '1D'
    ? (item) => getShortHour(item.record_date)
    : (item) => getShortDate(item.record_date);

  chart.data = {
    labels: data.map(generateLabel),
    datasets: [{
      label: 'Conservative',
      data: data.map(e => e.c_bias),
      ...defaultOptions.conservative
    }, {
      label: 'Liberal',
      data: data.map(e => e.l_bias),
      ...defaultOptions.liberal
    }, {
      label: 'Swing',
      data: data.map(e => e.s_bias),
      ...defaultOptions.swing
    }]
  };

  chart.update();
  return chart;
}
