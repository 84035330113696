import { fetchJson } from './fetch';
import { Mutex, withTimeout } from 'async-mutex';

const EXPIRE_TIME = 5 * 60 * 1000;
const FETCH_TIMEOUT = 10 * 1000;
const gCachedData = {};
const mutex = withTimeout(new Mutex(), FETCH_TIMEOUT, new Error('fetch-all-json timed out.'));

async function fetchAllJson () {
  const allData = await fetchJson('/api/all.json');
  return allData;
}

export async function fetchAllJsonCached (expireTime = EXPIRE_TIME) {
  let data = null;
  await mutex.runExclusive(async () => {
    const now = Date.now();
    if (gCachedData.data === undefined || expireTime === null || gCachedData.time < now - expireTime) {
      gCachedData.time = now;
      gCachedData.data = await fetchAllJson();
    }
    data = gCachedData.data;
  });
  return JSON.parse(JSON.stringify(data));
}
