/**
 *
 */
export function getLastXDays (X, startDate = new Date()) {
  const dates = [];

  for (let i = X - 1; i >= 0; i--) {
    const pastDate = new Date(startDate);
    pastDate.setDate(startDate.getDate() - i);
    dates.push(pastDate);
  }

  return dates;
}

/**
 *
 */
export function getLastXHours (X, startDate = new Date()) {
  const dates = [];

  for (let i = X - 1; i >= 0; i--) {
    const pastDate = new Date(startDate);
    pastDate.setHours(startDate.getHours() - i);
    dates.push(pastDate);
  }
  console.log(dates);
  return dates;
}

/**
 *
 */
export function formatDate (dateStr) {
  const date = new Date(dateStr);
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear());
  const hours = String(date.getHours() + 1).padStart(2, '0'); // Hours are 0-indexed
  const amOrPmHours = hours % 12 || 12; // Converts to 12-hour format
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  return {
    year,
    month,
    day,
    hours,
    amOrPmHours,
    amOrPm
  };
}
