import './style.css';
import { initGoogleGraph } from './google';
import { initBingBias } from './bing';
import { initYoutubeGraph } from './youtube';
import { initYahooGraph } from './yahoo';
import { initMissionGraph } from './mission';
import { initRedditGraph } from './reddit';
import { initTikTokGraph } from './tiktok';
import { initXGraph } from './x';
import { getDebugValue } from '../../util';

const largeContainer = '#bias-graph-large';
const firstSmallContainer = '#bias-graph-small-first';
const secondSmallContainer = '#bias-graph-small-second';

/**
 *
 * @param {*} leftDiv
 * @param {*} rightDiv
 */
function slideBiasRight (leftDiv, rightDiv) {
  leftDiv.style.transform = 'translateX(0)';
  leftDiv.style.opacity = '1';
  rightDiv.style.transform = 'translateX(20%)';
  rightDiv.style.opacity = '0';
}

/**
 *
 * @param {*} leftDiv
 * @param {*} rightDiv
 */
function slideBiasLeft (leftDiv, rightDiv) {
  leftDiv.style.transform = 'translateX(-20%)';
  leftDiv.style.opacity = '0';
  rightDiv.style.transform = 'translateX(0)';
  rightDiv.style.opacity = '1';
}

/**
 *
 * @param {*} leftDiv
 * @param {*} rightDiv
 */
function autoSlide (leftDiv, rightDiv, delay = 6500) {
  setTimeout(() => {
    slideBiasRight(leftDiv, rightDiv);

    setTimeout(() => {
      slideBiasLeft(leftDiv, rightDiv);
      autoSlide(leftDiv, rightDiv);
    }, delay);
  }, delay);
}

/**
 *
 */
function delayStart (initializer, containerId, timeout) {
  return new Promise(resolve => {
    setTimeout(async () => {
      const result = await initializer(containerId);
      resolve(result);
    }, timeout);
  });
}

/**
 *
 */
export async function initBiasGraphs () {
  await Promise.allSettled([
    delayStart(initGoogleGraph, largeContainer, 25),
    delayStart(initBingBias, largeContainer, 50),
    delayStart(initMissionGraph, firstSmallContainer, 75),
    delayStart(initYahooGraph, firstSmallContainer, 100),
    delayStart(initYoutubeGraph, firstSmallContainer, 125),
    delayStart(initRedditGraph, secondSmallContainer, 150),
    delayStart(initTikTokGraph, secondSmallContainer, 175),
    delayStart(initXGraph, secondSmallContainer, 200)
  ]);

  if (getDebugValue() === '1') {
    const containers = document.querySelectorAll('.slide-tray');
    let staggeredSlideStart = 0;

    containers.forEach(container => {
      const leftDiv = container.querySelector('.left-div');
      const rightDiv = container.querySelector('.right-div');

      autoSlide(leftDiv, rightDiv, 6500 + staggeredSlideStart);
      staggeredSlideStart += 300;
    });
  }

  return true;
}
