import { EphemeralCounts } from './ephemeral-counts';
import { fetchAllJsonCached } from '../../fetch-all-json';

let g_cached_counts = null;

export function blah () {
  return 1;
}

async function getEphemeralCounts () {
  const response = await fetchAllJsonCached(10000);
  const data = response.eph_counts;
  // Get the Expires header from the response
  // const expirationTime = response.headers.get('Expires');
  // 'Cache-Control'
  const out = new EphemeralCounts(
    data.first_count,
    data.updated,
    data.second_count,
    data.second_updated,
    data.interval_seconds);
  return out;
}

export async function getCachedEphemeralCounts () {
  if (g_cached_counts) {
    if (!g_cached_counts.isExpired()) {
      return g_cached_counts;
    }
  }
  g_cached_counts = await getEphemeralCounts();
  return g_cached_counts;
}
