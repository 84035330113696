import { isDebug, isDev } from '../../util';

import posthog from 'posthog-js';

const TOKEN = 'phc_Blkhw9x6T83ab0PUs9jR1QG2FJeQhrpi2YBr9rvysKD';

function getConfig (debug = false) {
  const isLocalHost = window.location.hostname === 'localhost';
  const config = {
    api_host: 'https://panopticon-qq5x.onrender.com/Lucgd6NSPn8OcKhN',
    ui_host: 'app.posthog.com',
    autocapture: {
      dom_event_allowlist: ['click'], // DOM events from this list ['click', 'change', 'submit']
      url_allowlist: ['posthog.com./docs/.*'], // strings or RegExps
      element_allowlist: ['button'] // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
    }
  };
  if ((isLocalHost || isDev()) && !isDebug()) {
    config.disable_session_recording = true;
    config.disable_cookie = true;

    if (debug === true) {
      const reason = isLocalHost ? 'localhost' : 'dev';
      console.warn('Analytics disabled for ' + reason);
    }
  }
  return config;
}

export async function initAnalytics (debug = false) {
  const config = getConfig(debug);
  posthog.init(
    TOKEN,
    config
  );
  const response = posthog.capture('analytics_initialized', { property: true });

  if (debug === true) {
    console.log(response);
    console.log('Analytics initialized');
  }
}
