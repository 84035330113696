import { fetchAllJsonCached } from '../../fetch-all-json';

function getRecentBiasRatings (allData) {
  const platform_bias = allData.bias_ratings.platform_bias;
  const recentBiases = {};
  for (const platform in platform_bias) {
    const data = platform_bias[platform];
    const bias = data.second_rating;
    const updated = data.updated;
    recentBiases[platform] = { bias, updated };
  }
  return recentBiases;
}

export async function fetchBiasData (graphtype, platformname) {
  const allData = await fetchAllJsonCached();

  // updateBiasDataWithHourlyInformation(allData);
  const recentBiasRatings = getRecentBiasRatings(allData);
  const allTimeData = allData[graphtype];
  // Merge in the recent bias rating with the historical graph
  const data = allTimeData.platform_bias[platformname];

  if (platformname in recentBiasRatings) {
    const { bias, updated } = recentBiasRatings[platformname];
    const lastItem = {
      bias_score: bias,
      record_date: updated,
      summary_date: updated
    };
    data.push(lastItem);
  } else {
    console.error(`No recent bias for ${platformname}`);
  }

  return data;
}

export async function fetchVoteBiasData (graphtype, platformName) {
  const allData = await fetchAllJsonCached();
  const platforms = allData.go_vote_metrics;
  const data = platforms[platformName][graphtype];
  return data;
}
