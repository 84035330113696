import './assets/css/purecss-3.0.0-min.css';
import './assets/css/purecss-3.0.0.grids-responsive-min.css';
import './src/layout.css';
import './src/patch-console-log';
import { initApp } from './src/app';

// This probably needs to always be the last import to make sure responsiveness styling takes priority in the cascade load
import './src/responsive.css';

async function main (): Promise<void> {
  try {
    await initApp();
  } catch (error) {
    console.error('Error initializing the application', error);
  }
}

main();
