import './swiper-bundle.min.css';
import './style.css';
import Swiper from 'swiper/bundle';
import htmlFrag from './content.frag.html';
import { loadHtmlFragment } from '../../load-html-fragment';
import { fetchAllJsonCached } from '../../fetch-all-json';

const CAROUSEL_UPDATE_DELAY = 250;

let gSwiper = null;

async function fetchYtFileNames () {
  const response = await fetchAllJsonCached();
  return response.yt_file_names.yt_file_names;
}

function createCarouselData (imagesUrls) {
  let html = '<div id="carousel" class="swiper mySwiper">\n';
  html += '<div class="swiper-wrapper">\n';
  for (const imgUrl of imagesUrls) {
    html += `<div class="swiper-slide"><img src="${imgUrl}" loading="lazy"></div>\n`;
  }
  html += '</div>\n';
  html += '<!-- Add Pagination -->\n';
  html += '<div class="swiper-pagination"></div>\n';
  html += '<!-- Add Navigation -->\n';
  html += '<div class="swiper-button-prev"></div>\n';
  html += '<div class="swiper-button-next"></div>\n';
  html += '</div>\n';
  return html;
}

function getImgUrl (imgName) {
  const rawUrl = `/assets/yt-imgs/${imgName}`;
  // url encode path
  const encodedUrl = encodeURI(rawUrl);
  return encodedUrl;
}

function randomShuffle (array) {
  const newArray = [...array];

  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex], newArray[currentIndex]];
  }

  return newArray;
}

function loadSwiper (content) {
  const $carousel = document.getElementById('youtube-carousel');
  $carousel.innerHTML = content;
  // For options see:
  //   https://swiperjs.com/swiper-api#parameters
  return new Swiper('.mySwiper', {
    slidesPerView: 3,
    spaceBetween: 30,
    virtual: {
      enabled: true,
      addSlidesAfter: 3,
      addSlidesBefore: 3
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      type: 'bullets',
      /* increase size */
      bulletActiveClass: 'swiper-pagination-bullet-active',
      bulletClass: 'swiper-pagination-bullet',
      clickableClass: 'swiper-pagination-clickable',
      currentClass: 'swiper-pagination-current',
      hiddenClass: 'swiper-pagination-hidden',
      lockClass: 'swiper-pagination-lock',
      modifierClass: 'swiper-pagination-',
      progressbarFillClass: 'swiper-pagination-progressbar-fill',
      progressbarOppositeClass: 'swiper-pagination-progressbar-opposite'
    },
    autoplay: {
      delay: 7500
    },
    slidesPerGroup: 1,
    loop: false,
    speed: 1000
  });
}

function changeSwiperSlidesPerView (newValue) {
  gSwiper.params.slidesPerView = newValue;
  gSwiper.update();
}

function updateCarousel () {
  const $odeometerContainer = document.querySelector('#yt_carousel');
  const width = $odeometerContainer.clientWidth;
  if (width < 400) {
    changeSwiperSlidesPerView(1);
  } else if (width < 1100) {
    changeSwiperSlidesPerView(2);
  } else {
    changeSwiperSlidesPerView(3);
  }
}

export async function initYtCarousel () {
  loadHtmlFragment('#yt_carousel', htmlFrag);
  const $ytCarousel = document.getElementById('youtube-carousel');
  const imgNames = await fetchYtFileNames();
  const imgUrls = randomShuffle(imgNames.map(getImgUrl));
  const content = createCarouselData(imgUrls);
  gSwiper = loadSwiper(content);

  let resizeTimeoutJob = null;
  const resizeObserver = new ResizeObserver(() => {
    clearTimeout(resizeTimeoutJob); // Cancel any previous job.
    resizeTimeoutJob = setTimeout(updateCarousel, CAROUSEL_UPDATE_DELAY);
  });
  resizeObserver.observe($ytCarousel);
}
