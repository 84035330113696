import html from './youtube.frag.html';
import { createBiasChart } from '../bias-graph';

export async function initYoutubeGraph (containerId) {
  const $container = document.querySelector(containerId);
  $container.insertAdjacentHTML('beforeend', html);

  const CHART_ID = '#youtube-bias';

  /*
  legacy code tos how "Comming soon"
  if (false) {
    const comingSoon = document.querySelector(`${CHART_ID} .coming-soon`);
    const biasNumber = document.querySelector(`${CHART_ID} .bias-number`);

    comingSoon.style.display = 'flex';
    biasNumber.style.display = 'none';

    return false;
  }
  */

  const options = {
    platformName: 'youtube', // @TODO: delete when youtube data is ready
    // platformName: 'youtube', // @TODO: uncomment when youtube data is ready
    chart_height: 265,
    chart_width: 475,
    gradient_height: 150,
    default_range: '3M',
    selectors: {
      disabledOverlay: `${CHART_ID} .chartDisabledOverlay`,
      chart: `${CHART_ID} .canvasContainer`,
      canvas: `${CHART_ID} canvas`,
      range: `${CHART_ID} .time-range-selection .time-range-option`
    }
  };

  await createBiasChart(options);
  return true;
}
