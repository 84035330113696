import html from './index.frag.html';
import './index.css';
import { isDebug, isMobile } from '../../util';

const SPLASH_SCREEN_DISABLED = true;
const LOCAL_STORAGE_NAME = 'splashActivatedInfo';

function getData () {
  const data = localStorage.getItem(LOCAL_STORAGE_NAME);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

function setData (activatedThisSession, date) {
  const payload = JSON.stringify({
    activatedThisSession,
    date
  });
  localStorage.setItem(LOCAL_STORAGE_NAME, payload);
}

function finalize () {
  const el = document.getElementById('background-video');
  document.getElementById('header').style.display = 'none';
  document.body.classList.remove('preload');
  document.documentElement.classList.remove('preload');
  el.pause();
}

export function splashActivatedThisSession () {
  return !!getData().activatedThisSession;
}

export async function initSplash () {
  const data = getData();
  if (data === null) {
    setData(false, null);
  }

  const splashActivatedDate = getData().date;
  if ((splashActivatedDate && !isDebug()) || SPLASH_SCREEN_DISABLED) {
    const maxHours = 24;
    const dateDiff = new Date().getTime() - splashActivatedDate;
    const hoursDiff = dateDiff / 1000 / 60 / 60;
    if (hoursDiff < maxHours || SPLASH_SCREEN_DISABLED) {
      document.body.classList.remove('preload');
      document.documentElement.classList.remove('preload');
      setData(false, null);
      return;
    }
  }
  setData(true, new Date().getTime());
  const $dom = document.querySelector('#header');
  $dom.outerHTML = html;
  // html.preload, body.preload
  // add preload class to body and to html
  document.body.classList.add('preload');
  document.documentElement.classList.add('preload');
  // const vidEl = document.getElementById('background-video');

  const enabled = !isMobile();
  const el = enabled ? document.getElementById('background-video') : document.getElementById('background-image');
  const listenForData = enabled ? 'loadeddata' : 'load';
  const dataTimeout = enabled ? 0 : 0;

  document.getElementById('shield-image').onload = function runSplash () {
    setTimeout(function setOpacity () {
      this.style.opacity = '1';
    }.bind(this), 500);
    if (enabled) {
      const bgvid = document.getElementById('background-video');
      bgvid.src = 'https://techwatchproject.github.io/americanflag/small.mp4';
      // play
      bgvid.play();
    } else {
      setTimeout(function setOpacity () {
        el.style.opacity = '1';
      }, 1200);
    }
  };

  if (enabled) {
    el.addEventListener(listenForData, function doSetTimeout () {
      setTimeout(function setOpacity () {
        el.style.opacity = '1';
      }, dataTimeout);
    });
  }

  document.getElementById('svg-header').onload = function setOpacity () {
    this.style.opacity = '1';
  };

  setTimeout(function doHide () {
    document.getElementById('header').classList.add('hide');
  }, 4000);

  setTimeout(function doFinalize () {
    finalize();
  }, 4200);

  // --header-body-overflow: hidden
  // set the css value
  // document.body.style.setProperty('--header-body-overflow', 'hidden');

  // on loaded.
  // document.querySelector(".background-container").style.opacity = '1';
}
