/* eslint-disable @typescript-eslint/no-unused-vars */

import './info-box.css';
import Swal from 'sweetalert2';

const ENABLE_NEW_POPUP = true;

// TODO: remove dead code. This class was refactored.
export async function initInfoBoxes () {
  // Configuration for all info boxes
  const infoConfig = [
    { buttonId: 'eph-button', boxId: 'ephemeral-info', showClass: 'lpk-show-info-box' },
    { buttonId: 'yti-button', boxId: 'yt-info', showClass: 'show-yt-info-box' },
    { buttonId: 'ggl-button', boxId: 'ggl-info', showClass: 'google-show-info-box' },
    { buttonId: 'yh-button', boxId: 'yh-info', showClass: 'yahoo-show-info-box' },
    { buttonId: 'bn-button', boxId: 'bn-info', showClass: 'bing-show-info-box' },
    { buttonId: 'sm-button', boxId: 'sm-info', showClass: 'static-map-show-info-box' },
    { buttonId: 'im-button', boxId: 'im-info', showClass: 'interactive-map-show-info-box' },
    { buttonId: 'ef-button', boxId: 'ef-info', showClass: 'election-flipped-show-info' },
    { buttonId: 'mt-button', boxId: 'mt-info', showClass: 'balanced-data-show-info' },
    { buttonId: 'yt-button', boxId: 'ytb-info', showClass: 'youtube-show-info' },
    { buttonId: 'privacy-button', boxId: 'privacy-info', showClass: 'privacy-show-info' },
    { buttonId: 'disclaimer-button', boxId: 'disclaimer-info', showClass: 'disclaimer-show-info' },
    { buttonId: 'methodology-button', boxId: 'methodology-info', showClass: 'methodology-show-info' }
    // Add more configurations here as needed
  ];

  const infoEphemeral = {
    title: '"Ephemeral Experiences” Captured',
    html: `
    <div class="msg-box-text">
      <p>
        This counter depicts the continuously growing tally of the ephemeral experiences 
        that we capture and analyze daily. Our research provides insight into the number
        of undecided votes that can be influenced prior to an election.
      </p>
      <p>
        Google and other tech companies are able to use ephemeral content
        to manipulate our elections, our children, and all of us. Beginning
        in 2016, we have been building larger and larger systems to track
        content being sent to children and voters in the US.
      </p>
      <p>
        Since late 2022,
        The Digital Shield project has allowed us to capture, archive, and
        analyze ephemeral content 24 hours a day through the computers of a
        politically balanced group of tens of thousands of registered voters
        in all 50 US states. We call these people our "field agents."
      </p>
    </div>`,
    buttonHtml: 'Support Our Work',
    buttonLink: 'https://techwatchproject.org/donate/'
  };

  const infoYtShocking = {
    title: 'Exposing the Shocking Videos Kids See on YouTube Today',
    html: `
    <div class="msg-box-text">
      <p>
        Our Youth Content Project is dedicated to preserving “ephemeral experiences”
        directed at children under the age of 18. YouTube is the most widely used application.
        Within this carousel, we unveil the ephemeral content recommended to children through
        "Up Next" videos, which you may find disturbing, harmful, or predatory in nature.
      </p>
    </div>`
  };

  const infoGooglesPoliticalBias = {
    title: 'Google’s Political Bias',
    html: `
    <div class="msg-box-text">
      <p>
        This graph presents precise measurements of political bias observed on Google.
        This analysis is based on a politically-balanced sample of registered voters
        spanning all 50 U.S. states.
      </p>
      <p>
        Biased content has the potential to sway the voting preferences of a substantial portion of undecided voters, ranging from 20% to 80%. This significant influence can play a pivotal role in tipping the balance in closely contested elections. The bias generated by Google's algorithms poses a unique challenge to the integrity of free and fair elections. Unlike traditional campaign strategies, it cannot be directly countered by political campaigns, making it a critical issue to address.
      </p>
    </div>`
  };

  const infoBingsPoliticalBias = {
    title: "Bing's Political Bias",
    html: `
    <div class="msg-box-text">
      <p>
        This graph presents precise measurements of political bias observed on Bing. This analysis is based on a politically-balanced sample of registered voters spanning all 50 U.S. states.
      </p>
      <p>
        Biased content has the potential to sway the voting preferences of a substantial portion of undecided voters, ranging from 20% to 80%. This significant influence can play a pivotal role in tipping the balance in closely contested elections.
      </p>
      <p>
        The bias generated by Bing's algorithms poses a unique challenge to the integrity of free and fair elections. Unlike traditional campaign strategies, it cannot be directly countered by political campaigns, making it a critical issue to address.
      </p>
    </div>
  `
  };

  const infoYahoosPoliticalBias = {
    title: 'Yahoo’s Political Bias',
    html: `
    <div class="msg-box-text">
    <p>
      This graph presents precise measurements of political bias observed on Yahoo. This analysis is based on a politically-balanced sample of registered voters spanning all 50 U.S. states.
    </p>
    <p>
      Biased content has the potential to sway the voting preferences of a substantial portion of undecided voters, ranging from 20% to 80%. This significant influence can play a pivotal role in tipping the balance in closely contested elections.
      The bias generated by Yahoo's algorithms poses a unique challenge to the integrity of free and fair elections. Unlike traditional campaign strategies, it cannot be directly countered by political campaigns, making it a critical issue to address.
    </p>
    </div>`
  };

  const infoUsaPoliticalLandscape = {
    title: 'USA Political Landscape',
    html: 'This static data is sourced from <a href="https://web.archive.org/web/20230331032115/https://wisevoter.com/state-rankings/red-and-blue-states/" target="_blank">https://web.archive.org/web/20230331032115/https://wisevoter.com/state-rankings/red-and-blue-states/ </a>. It provides the current political leanings of each state. To delve deeper into how big tech companies distribute biased content in each state, please refer to the map on the right.'
  };

  const infoContentBiasMap = {
    title: 'Content Being Sent by Google',
    html: 'This interactive map reveals the real-time political bias in ephemeral content distribution to each state. Hover over any state to view its specific bias. Take note of the disparities between this map and the one on the left. How might this skewed content distribution impact our perceptions and decisions?'
  };

  const infoElectionsFlippedByGoogle = {
    title: 'Elections Likely Flipped by Google',
    html: 'The election results table to the left shows the actual results, while the table on the right illustrates how elections might have looked without Google and other Big Tech\'s influence through ephemeral experience bias directed at undecided voters.'
  };

  const infoBalancedDataCollection = {
    title: 'Balanced Data Collection',
    html: `
    <div class="msg-box-text">
      <p>
        With their permission, we're collecting data 24 hours a day through the computers and mobile devices of a politically balanced group of tens of thousands of registered voters in all 50 US states. We're also monitoring content being sent to their children, looking for signs of political indoctrination and harmful content. Unlike Google and Facebook, we never violate privacy. Data is transmitted to us without identifying information, and we only analyze aggregate data, never individual data.
      </p>
      <p>
        Our dedicated field agents, motivated by service to our nation, receive token compensation for their invaluable contributions. Join us in supporting their vital work in building a more balanced democracy.
      </p>
    </div>`,
    buttonText: 'Sponsor a Watchdog',
    buttonLink: 'https://givebutter.com/americasdigitalshield'
  };

  const infoYoutubePoliticalBias = {
    title: 'YouTube’s Political Bias',
    html: `
    <div class="msg-box-text">
      <p>
        This graph presents precise measurements of political bias observed on YouTube.
        This analysis is based on a politically-balanced sample of registered voters
        spanning all 50 U.S. states.
      </p>
      <p>
        Biased content has the potential to sway the voting preferences of a substantial portion of undecided voters, ranging from 20% to 80%. This significant influence can play a pivotal role in tipping the balance in closely contested elections. The bias generated by YouTube's algorithms poses a unique challenge to the integrity of free and fair elections. Unlike traditional campaign strategies, it cannot be directly countered by political campaigns, making it a critical issue to address.
      </p>
    </div>`
  };

  const infoPrivacy = {
    title: 'Privacy Policy for AmericasDigitalShield.org',
    html: `
    <p><small>Last updated: 12/2/23</small></p>

    <h2>Introduction</h2>
    <small>
      <p>This Privacy Policy outlines the policies and procedures of AmericasDigitalShield.org, owned and operated by the American Institute for Behavioral Research and Technology, on the collection, use, and disclosure of your information when you use our Service. This policy also informs you about your privacy rights.</p>
      <p>In human language, we will use information related to your page visits and the links you click on in order to improve our website primarily to have greater social impact. Later we may amend this privacy policy as we strive for deeper insights on the use of our website. Anonymized and aggregate information may be shared publically through the media and congressional testimony. Identifying information like the IP address you used to access the site is anonymized when sharing with 3rd parties.</p>
    </small>

    <h2>Commitment to Privacy</h2>
    <small>
      <p>We prioritize the privacy of our users.</p>
      <p>Your data will not be sold to any third party, but may be shared.</p>
      <p>We take reasonable steps to ensure secure storage of your information.</p>
      <p>For privacy concerns, contact us at <a href="mailto:info@americasdigitalshield.org">info@americasdigitalshield.org</a>.</p>
    </small>

    <h2>Interpretations and Definitions</h2>
    <small>
      <p>Company: Refers to the American Institute for Behavioral Research and Technology, operating AmericasDigitalShield.org.</p>
      <p>Country: United States, specifically focusing on compliance with laws applicable to New Mexico.</p>
      <p>Device: Any device accessing our Service, including computers and mobile devices.</p>
      <p>Personal Data: Information relating to an identified or identifiable individual.</p>
      <p>Service: The AmericasDigitalShield.com website.</p>
      <p>Service Provider: Any person or entity processing data on behalf of the Company.</p>
      <p>Usage Data: Data collected automatically through service use (e.g., page visit duration).</p>
      <p>Website: AmericasDigitalShield.org</p>
      <p>You: The individual using our Service or the entity on behalf of which such individual is accessing our Service.</p>
    </small>

    <h2>Collecting and Using Your Personal Data</h2>
    <h3>Types of Data Collected</h3>
    <small>
      <p>Personal Data: We may ask for personal information such as your name and contact details for communication and service provision.</p>
      <p>Usage Data: We collect data generated by your use of our Service.</p>
    </small>

    <h3>Tracking Technology and Cookies</h3>
    <small>
      <p>We use cookies and similar tracking technologies for service operation and user experience enhancement.</p>
    </small>

    <h3>Use of Your Personal Data</h3>
    <small>
      <p>To maintain and monitor our Service.</p>
      <p>To contact you with updates, offers, and other relevant information.</p>
      <p>For data analysis, identifying usage trends, and service improvement.</p>
      <p>We may share your information with Service Providers or with your consent.</p>
    </small>

    <h3>Retention of Your Personal Data</h3>
    <small>
      <p>We retain your Personal Data as long as necessary for our service provision and legal compliance.</p>
    </small>

    <h3>Transfer of Your Personal Data</h3>
    <small>
      <p>Your data may be transferred and maintained on computers outside your state or country where data protection laws may differ.</p>
    </small>

    <h3>Security of Your Personal Data</h3>
    <small>
      <p>We strive to protect your Personal Data but cannot guarantee absolute security.</p>
    </small>

    <h2>Children’s Privacy</h2>
    <small>
      <p>Our Service does not knowingly collect information from anyone under 18.</p>
      <p>Our Service may contain links to external sites not operated by us. Review their privacy policies if you visit them.</p>
    </small>

    <h2>Changes to this Privacy Policy</h2>
    <small>
      <p>We may update our Privacy Policy periodically. Review it regularly for changes.</p>
    </small>

    <h2>Contact Us</h2>
    <small>
      <p>For questions about this Privacy Policy, contact us at <a href="mailto:info@americasdigitalshield.org">info@americasdigitalshield.org</a> or call 505-404-1691</p>
    </small>
  `
  };

  const infoDisclaimer = {
    title: 'Disclaimer',
    html: `<h2>Terms of Use</h2>
    <small>
      <small>
        <p>
          Welcome to our website! Before using our website, we kindly ask you to read these Terms of Use carefully, as they govern your use of our website. By accessing and using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.
        </p>
        <h3>Intellectual Property Disclosure</h3>
        <p>
          All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of American Institute for Behavioral Research and Technology. It is protected by United States and international copyright
          laws. You are prohibited from reproducing, distributing, displaying, or creating derivative works of any content on this website without our prior written consent, unless otherwise governed by fair use.
        </p>
        <h3>Governing Law of The State Of New Mexico</h3>
        <p>
          These Terms of Use are governed by and construed in accordance with the laws of the State of New Mexico, without regard to its conflict of law principles.
        </p>
        <h3>Links To Other Websites Clause</h3>
        <p>
          Our website may contain links to third-party websites not owned or controlled by us. We are not responsible for the content, privacy policies, or practices of these third-party websites. By using our website, you release us from any liability arising from your use of these third-party websites.
        </p>
        <h3>Revision of Terms</h3>
        <p>
          We may revise these Terms of Use at any time by updating this page. Your continued use of the website after such changes will constitute your acceptance of the new Terms of Use.
        </p>
        <h3>Copyright Policy</h3>
        <p>
          If you believe that content on our website infringes your copyright,
          please send a notice of infringement to our designated copyright
          agent, including all required information as previously detailed.
        </p>
        <h3>Disclaimer of Warranties and Limitation of Liability</h3>
        <p>
          This website is provided on an "as is" and "as available" basis. We disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the website will be uninterrupted or error-free. We will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of the website.
        </p>
        <h3>User Conduct and Responsibilities</h3>
        <p>
          Users are expected to use the website responsibly and ethically. Engaging in illegal activities or violating others' rights is strictly prohibited. Users are responsible for maintaining the confidentiality of their account information.
        </p>
        <h3>Termination Clause</h3>
        <p>
          We reserve the right to terminate or restrict your access to the website for any violation of these Terms of Use, or for any other reason, at our discretion.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless American Institute for Behavioral Research and Technology from any claims, liabilities, expenses, or damages, including attorneys' fees, arising from your use of the website or your violation of these Terms.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Any disputes arising from these Terms of Use will be resolved through final and binding arbitration, except where prohibited by law.
        </p>
        <h3>Accessibility Statement</h3>
        <p>
          We are committed to ensuring digital accessibility for people with disabilities and are continually working to improve the user experience for everyone.
        </p>
        <h3>Contact Us</h3>
        <p>
          For any questions about these Terms of Use, please contact us at
          <a href="mailto:info@techwatchproject.org">info@techwatchproject.org</a> or call (405) 515-6780.
        </p>
        <h2>Privacy & Cookies</h2>
        <p>
          This Cookie Policy explains how we use cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
        </p>
        <h3>What are Cookies?</h3>
        <p>
          Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
        </p>
        <h3>Types of Cookies Used</h3>
        <p>
          Essential Cookies: These are necessary for the website to function and cannot be switched off in our systems. They are usually set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.
        </p>
        <p>
          Performance and Analytics Cookies: These allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.
        </p>
        <p>
          Functional Cookies: These enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.
        </p>
        <p>
          Advertising Cookies: These may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
        </p>
        <h3>Managing Cookies</h3>
        <p>
          You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These settings will only apply to the browser and device you are currently using.
        </p>
        <h3>Your Choices</h3>
        <p>
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by setting or amending your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.
        </p>
      </small>
    </small>
`
  };

  const infoMethodology = {
    title: 'Methodology',
    html: `
    <large>
      <p>AIBRT researchers use extremely rigorous, scientifically valid methods in all of their research, which regularly undergoes peer review for presentation at scientific meetings and ultimately undergoes more extensive peer review for publication in scientific journals, among them two of the most prestigious multidisciplinary journals in the world: the Proceedings of the National Academy of Sciences USA, and PLOS ONE.</p>
      <p>To examine our methodology, please see any of our published papers, or see the methodology summary in Appendix II (p. 34) of Dr. Epstein's 52-page 2022 report, Google's Triple Threat: To Our Democracy, Our Children, and Our Minds, accessible at:</p>
      <p><a href="https://GooglesTripleThreat.com" target="_blank">https://GooglesTripleThreat.com</a></p>
      <p>For further details, please see the written version of Dr. Epstein's 2023 testimony before the U.S. Senate Judiciary's Subcommittee on Competition Policy, Antitrust, and Consumer Rights. This is a 480-page monograph that includes a Methodology section (p. 24) and thirteen recent scientific papers.</p>
      <p><a href="https://aibrt.org/downloads//EPSTEIN_2023-Americas_Digital_Shield-Written_Testimony-Senate_Judiciary-13December2023-g.pdf" target="_blank">https://aibrt.org/downloads//EPSTEIN_2023-Americas_Digital_Shield-Written_Testimony-Senate_Judiciary-13December2023-g.pdf</a></p>
      <p>AIBRT's experiments adhere to the very highest possible scientific standards, which means they are randomized, controlled, counterbalanced, and double-blind.</p>
      <p>Our online monitoring project is currently collecting data from the computers of a politically-balanced group of more than 13,000 registered voters in all 50 states, and we generally add between 30 and 60 new "field agents" to this group every day.</p>
      <p>To view Dr. Epstein biography, please see:</p>
      <p><a href="https://aibrt.org/downloads/BIO-Dr._Robert_Epstein.pdf" target="_blank">https://aibrt.org/downloads/BIO-Dr._Robert_Epstein.pdf</a></p>
      <p>Further details will be added to this web page in coming weeks.</p>
    </large>
    `
  };

  // The cover box that may be common for all info boxes
  const coverBox = document.getElementById('coverBox');

  const gMap = {
    'ephemeral-info': infoEphemeral,
    'yt-info': infoYtShocking,
    'ggl-info': infoGooglesPoliticalBias,
    'bn-info': infoBingsPoliticalBias,
    'yh-info': infoYahoosPoliticalBias,
    'sm-info': infoUsaPoliticalLandscape,
    'im-info': infoContentBiasMap,
    'ef-info': infoElectionsFlippedByGoogle,
    'mt-info': infoBalancedDataCollection,
    'ytb-info': infoYoutubePoliticalBias,
    'privacy-info': infoPrivacy,
    'disclaimer-info': infoDisclaimer,
    'methodology-info': infoMethodology
  };

  function getInfoBox (id) {
    const val = gMap[id];
    if (val === undefined) {
      console.error(`Could not find info box with id ${id}`);
      return undefined;
    }
    return { ...val }; // By copy.
  }

  function showPopup (id) {
    const data = getInfoBox(id);
    if (data === undefined) {
      console.error(`Could not find info box with id ${id}`);
      return;
    }
    data.customClass = 'popup-info-box-style';

    // Check if buttonLink and buttonHtml are provided
    if (data.buttonLink && data.buttonHtml) {
      // Add a custom confirmation button to the popup
      data.showConfirmButton = true;
      data.confirmButtonText = data.buttonHtml;
    } else {
      // Hide confirm button if buttonLink or buttonHtml is not provided
      data.showConfirmButton = true;
    }
    data.showCloseButton = true;
    data.animation = false;
    // remove buttonLink and buttonHtml from data
    const buttonLink = data.buttonLink;
    delete data.buttonLink;
    delete data.buttonHtml;

    // Display the popup
    Swal.fire(data).then((result) => {
      if (result.isConfirmed && buttonLink) {
        // Open the link in a new window
        window.open(buttonLink, '_blank');
      }
    });
  }

  // Function to toggle the display of an info box
  function toggleInfoBox ({ boxId, showClass }, show) {
    try {
      const infoBox = document.getElementById(boxId);
      if (show) {
        console.log(`clicked: ${boxId}`);
        if (ENABLE_NEW_POPUP) {
          showPopup(boxId);
        } else {
          coverBox.style.display = 'block';
          infoBox.classList.add(showClass);
        }
      } else {
        if (ENABLE_NEW_POPUP) {
          return;
        }
        infoBox.classList.remove(showClass);
        coverBox.style.display = 'none';
      }
    } catch (err) {
      console.error(`Could not find element with id ${boxId}`);
    }
  }

  // Setup event listeners based on configuration
  infoConfig.forEach(({ buttonId, boxId, showClass }) => {
    try {
      const infoBtn = document.getElementById(buttonId);
      infoBtn.onclick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        toggleInfoBox({ boxId, showClass }, true);
      };
    } catch (err) {
      console.error(`Could not apply info click handler to element with id ${buttonId} because of ${err}`);
    }
  });
}
