//
// This is an iOS hack. iOS does not allow video to be started programically, but only when it's
// invoked from a click event generated by the browser. So to do this we save the call chain to the
// activation (which is coming from a click event) and then selectively invoke it later when the video
// comes into view.

import html from './index.frag.html';
import './style.css';
import { activateVideo, deactivateVideo } from './info-player';
import { intersectionObserverInitLoop, enableIntersectionObserver } from './intersection-observer';
import { initClickCatcher } from './click-catcher';

function isRunningOnIOS () {
  const userAgent = window.navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

const IS_IOS = isRunningOnIOS();

const URL_PARAMS = new URLSearchParams(window.location.search);
const ENABLED = URL_PARAMS.get('vids');

// Because these videos use transparency, they can only be played on browsers that support HEVC or VP9.
// iOS does not support VP9 with transparency, apparently HEVC is supported, but I haven't been able
// to get it to work. This is documentation saying that they were able to do it.
// https://css-tricks.com/overlaying-video-with-transparency-while-wrangling-cross-browser-support/
// const ENABLED = true;
const VID_YOUTUBE = {
  vp9: '/assets/vids/info-youtube.webm',
  hevc: '/assets/vids/info-youtube.mp4'
};
const VID_EPHEM = {
  vp9: '/assets/vids/info-ephem.webm',
  hevc: '/assets/vids/info-ephem.mp4'
};
const VID_BIAS_GRAPHS = {
  vp9: '/assets/vids/info-bias-graphs.webm',
  hevc: '/assets/vids/info-bias-graphs.mp4'
};
const VID_NO_PAPER_TRAIL = {
  vp9: '/assets/vids/no-paper-trail.webm',
  hevc: '/assets/vids/no-paper-trail.mp4'
};

const VID_GOOGLE_ELECTIONS = {
  vp9: '/assets/vids/info-google-election.webm',
  hevc: '/assets/vids/info-google-election.mp4'
};

const VID_FINAL_WORD = {
  vp9: '/assets/vids/info-final-word.webm',
  hevc: '/assets/vids/info-final-word.mp4'
};

let gStarted = false;
let gStopped = false;

export async function internalInitInfoVid () {
  if (!ENABLED) {
    return;
  }
  const target = document.querySelector('#info-video');
  target.outerHTML = html;

  // wait for the page to load before enabling the intersection observer
  setTimeout(() => {
    enableIntersectionObserver(true);
  }, 500);

  const elements = {
    '#childhood-risk': VID_YOUTUBE,
    '#total_experiences_captures': VID_EPHEM,
    '#tech-giants-exposed': VID_BIAS_GRAPHS,
    '#election_flipped_by_google_container': VID_GOOGLE_ELECTIONS,
    '#google_reshaping_elections_container': VID_NO_PAPER_TRAIL,
    '#balanced_data_collection_container': VID_FINAL_WORD

  };
  const elementSelectors = Object.keys(elements);

  // let gCurrentPlaybackFunctor = null;
  // declare this an async
  let gCurrentPlaybackFunctor = null;

  async function onEnter (element) {
    console.log('entered', element);
    if (!gStarted) {
      const vidsrcs = elements[element];
      const vidsrc = IS_IOS ? vidsrcs.hevc : vidsrcs.vp9;
      if (IS_IOS) {
        // Massive iOS hack!!!
        // iOS does not allow video to be started programically, but only when it's
        // invoked from a click event generated by the browser. So to do this we
        // save the call chain to the activation (which is coming from a click event)
        // and then selectively invoke it later when the video comes into view.
        gCurrentPlaybackFunctor = async () => {
          gStarted = true;
          gStopped = false;
          await activateVideo(vidsrc, { autostart: true });
        };
      } else {
        gStarted = true;
        gStopped = false;
        await activateVideo(vidsrc, { autostart: true });
      }
    }
  }

  async function onExit (element) {
    console.log('exited', element);
    gCurrentPlaybackFunctor = null;
    if (gStarted && !gStopped) {
      gStarted = false;
      gStopped = true;
      await deactivateVideo();
    }
  }

  intersectionObserverInitLoop('#info-video', elementSelectors, onEnter, onExit);

  if (IS_IOS) {
    // On iOS we need to start the video on touch / click
    initClickCatcher(async () => {
      if (!gStarted) {
        // const vidsrcs = elements[elementSelectors[0]];
        // const vidsrc = vidsrcs.hevc;
        if (gCurrentPlaybackFunctor) {
          await gCurrentPlaybackFunctor();
        }
      }
    });
  }
}

export async function initInfoVideo () {
  await internalInitInfoVid();
}
