function clickCatcherDom () {
  const div = document.createElement('div');
  div.id = 'click-catcher';
  div.style.position = 'fixed';
  div.style.top = '0';
  div.style.left = '0';
  div.style.width = '100vw';
  div.style.height = '100vh';
  div.style.zIndex = '9999';
  div.style.pointerEvents = 'none';
  return div;
}

function initDom () {
  try {
    const dom = clickCatcherDom();
    document.body.appendChild(dom);
  } catch (e) {
    console.error('failed to append click catcher', e);
  }
}

export function initClickCatcher (callback) {
  // const events = ['click'];
  // click and tapped
  initDom();
  const events = ['click', 'touchend'];
  for (const event of events) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-loop-func
    document.body.addEventListener(event, (evt) => {
      // interaction = true;
      callback();
      // console.log('click-catcher clicked');
    });
  }
}
