import html from './arizona.frag.html';
import { createBarChart } from '../chart';

const CHART_ID = '#vote-arizona-bias';

/**
 *
 */
export async function initArizonaVote (containerId) {
  const $container = document.querySelector(containerId);
  $container.insertAdjacentHTML('beforeend', html);

  return createBarChart({
    platformName: 'arizona',
    height: 220,
    width: 475,
    default_range: '1W',
    selectors: {
      chart: `${CHART_ID} .canvasContainer`,
      canvas: `${CHART_ID} canvas`,
      range: `${CHART_ID} .time-range-selection .time-range-option`
    }
  });
}
