import { getBiasInfo } from '../../util';
import { fetchAllJsonCached } from '../../fetch-all-json';

const bias_classes = {
  extLib: 'bias-liberal-extreme',
  midLib: 'bias-liberal-significant',
  sltLib: 'bias-liberal-minimal',
  neutral: 'bias-mixed',
  sltCon: 'bias-conservative-minimal',
  midCon: 'bias-conservative-significant',
  extCon: 'bias-conservative-extreme'
};

const biasClassNames = Object.values(bias_classes);

async function fetchBiasNumbers () {
  const response = await fetchAllJsonCached();

  return {
    bias_ratings: response.bias_ratings,
    weekly_bias_graph: response.weekly_bias_graph
  };
}

/**
 *
 */
function populateBias (target, secondRating, weeklyRating, biasClasses, top, right = 0) {
  const number = parseFloat(secondRating);
  const weekly = parseFloat(weeklyRating);
  const [level, biasDescption] = getBiasInfo(number);
  const [weeklyLevel, weeklyBiasDescption] = getBiasInfo(weekly);

  target.number.innerHTML = number.toFixed(3);
  target.description.innerHTML = biasDescption;

  for (const className of biasClassNames) {
    target.box.classList.remove(className);
  }

  target.box.classList.add(biasClasses[level]);
  target.box.style.top = top;
  target.box.style.right = right;

  // Weekly
  target.weekly.number.innerHTML = weekly.toFixed(3);
  target.weekly.description.innerHTML = weeklyBiasDescption;

  for (const className of biasClassNames) {
    target.weekly.box.classList.remove(className);
  }

  target.weekly.box.classList.add(biasClasses[weeklyLevel]);
  target.weekly.box.style.top = top;
  target.weekly.box.style.right = right;
}

/**
 *
 */
async function biasNumbers () {
  const google = {
    number: document.getElementById('google_bias_number'),
    description: document.getElementById('google_bias_desc'),
    box: document.getElementById('google_bias_box'),
    weekly: {
      number: document.getElementById('google_weekly_bias_number'),
      description: document.getElementById('google_weekly_bias_desc'),
      box: document.getElementById('google_weekly_bias_box')
    }
  };

  const bing = {
    number: document.getElementById('bing_bias_number'),
    description: document.getElementById('bing_bias_desc'),
    box: document.getElementById('bing_bias_box'),
    weekly: {
      number: document.getElementById('bing_weekly_bias_number'),
      description: document.getElementById('bing_weekly_bias_desc'),
      box: document.getElementById('bing_weekly_bias_box')
    }
  };

  const yahoo = {
    number: document.getElementById('yahoo_bias_number'),
    description: document.getElementById('yahoo_bias_desc'),
    box: document.getElementById('yahoo_bias_box'),
    weekly: {
      number: document.getElementById('yahoo_weekly_bias_number'),
      description: document.getElementById('yahoo_weekly_bias_desc'),
      box: document.getElementById('yahoo_weekly_bias_box')
    }
  };

  const youtube = {
    number: document.getElementById('youtube_bias_number'),
    description: document.getElementById('youtube_bias_desc'),
    box: document.getElementById('youtube_bias_box'),
    weekly: {
      number: document.getElementById('youtube_weekly_bias_number'),
      description: document.getElementById('youtube_weekly_bias_desc'),
      box: document.getElementById('youtube_weekly_bias_box')
    }
  };

  const twitter = {
    number: document.getElementById('x_bias_number'),
    description: document.getElementById('x_bias_desc'),
    box: document.getElementById('x_bias_box'),
    weekly: {
      number: document.getElementById('x_weekly_bias_number'),
      description: document.getElementById('x_weekly_bias_desc'),
      box: document.getElementById('x_weekly_bias_box')
    }
  };

  const reddit = {
    number: document.getElementById('reddit_bias_number'),
    description: document.getElementById('reddit_bias_desc'),
    box: document.getElementById('reddit_bias_box'),
    weekly: {
      number: document.getElementById('reddit_weekly_bias_number'),
      description: document.getElementById('reddit_weekly_bias_desc'),
      box: document.getElementById('reddit_weekly_bias_box')
    }
  };

  try {
    const { bias_ratings, weekly_bias_graph } = await fetchBiasNumbers();

    for (const key of Object.keys(bias_ratings.platform_bias)) {
      const weekly = weekly_bias_graph.platform_bias[key];
      const secondRating = bias_ratings.platform_bias[key].second_rating || 0;
      const weeklyRating = weekly.reduce((accumulator, current) => {
        // Might as well calculate it here since we already have the data
        return accumulator + current.bias_score;
      }, 0) / weekly.length;

      switch (key) {
        case 'google':
          populateBias(google, secondRating, weeklyRating, bias_classes);
          break;
        case 'bing':
          populateBias(bing, secondRating, weeklyRating, bias_classes);
          break;
        case 'yahoo':
          populateBias(yahoo, secondRating, weeklyRating, bias_classes);
          break;
        case 'youtube':
          populateBias(youtube, secondRating, weeklyRating, bias_classes);
          break;
        case 'twitter':
          populateBias(twitter, secondRating, weeklyRating, bias_classes);
          break;
        case 'reddit':
          populateBias(reddit, secondRating, weeklyRating, bias_classes);
          break;
      }
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error.message);
  }
}

/**
 *
 */
export async function initCurrentBiasBoxes () {
  await biasNumbers();
  setInterval(async () => {
    await biasNumbers();
  }, 270000);
}
