import { interpolate, mapValues } from '../../util';

function parseMilliseconds (dateStr) {
  const date = new Date(dateStr);
  const out = date.getTime();
  return out;
}

export class EphemeralCounts {
  constructor (first_count, first_updated, second_count, second_updated, interval_seconds, expirationTime = undefined) {
    this.first_count = first_count;
    this.first_updated = parseMilliseconds(first_updated);
    this.second_count = second_count;
    this.second_updated = parseMilliseconds(second_updated);
    this.interval_seconds = interval_seconds;
    this.expirationTime = expirationTime ?? second_updated + (interval_seconds * 1000);
  }

  isExpired (now) {
    if (this.first_count === 0) {
      return true;
    }
    const currentTime = now || new Date().getTime();
    const tmp = this.expirationTime + (60 * 1000);
    return currentTime > tmp;
  }

  toJSONStr () {
    const obj = {
      first_count: this.first_count,
      first_updated: this.first_updated,
      second_count: this.second_count,
      second_updated: this.second_updated,
      interval_seconds: this.interval_seconds,
      expirationTime: this.expirationTime
    };
    const out = JSON.stringify(obj);
    return out;
  }

  static fromJSONStr (jstonStr) {
    const obj = JSON.parse(jstonStr);
    const out = new EphemeralCounts(
      obj.first_count,
      obj.first_updated,
      obj.second_count,
      obj.second_updated,
      obj.interval_seconds,
      obj.expirationTime);
    return out;
  }

  timeLeftUntilExpires (now) {
    const currentTime = now || new Date().getTime();
    const out = this.expirationTime - currentTime;
    return out;
  }

  getZerothCount () {
    const first_count = Number.parseFloat(this.first_count);
    const second_count = Number.parseFloat(this.second_count);
    const out = interpolate(-1, first_count, second_count);
    return out;
  }

  getZerothUpdated () {
    const first_updated = this.first_updated;
    const second_updated = this.second_updated;
    const out = interpolate(-1, first_updated, second_updated);
    return out;
  }

  interpolate (now) {
    const currentTime = now;// || new Date().getTime();
    // if time is before first_updated, then use the zeroth data values.
    const first_updated = this.first_updated;
    const second_updated = this.second_updated;
    if (currentTime > first_updated) {
      // interpolate between first and second
      const first_count = Number.parseFloat(this.first_count);
      const second_count = Number.parseFloat(this.second_count);
      const out = mapValues(now, first_updated, second_updated, first_count, second_count);
      return out;
    }
    // interpolate between zeroth and first
    const zeroth_count = this.getZerothCount();
    const zeroth_updated = this.getZerothUpdated();
    const first_count = Number.parseFloat(this.first_count);
    // const out = interpolate(t, zeroth, first_count);
    const out = mapValues(now, zeroth_updated, first_updated, zeroth_count, first_count);
    return out;
  }

  interpolateShifted (now) {
    const currentTime = now;// || new Date().getTime();
    const sum_interval = Number.parseFloat(this.interval_seconds) * 2;
    const interp = this.interpolate(currentTime - sum_interval);
    return Number.parseInt(interp, 10);
  }
}
