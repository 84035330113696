import { initLazyImageLoader } from './lazy-image-loader';
import { initAnalytics } from './components/analytics';
import { initSplash } from './components/splash';
import { initCurrentBiasBoxes } from './components/current-bias-boxes';
import { initInfoBoxes } from './components/info-box';

// import { isDebug } from './util';

export async function initApp (debug = false): Promise<void> {
  initLazyImageLoader();

  try {
    await initSplash();
  } catch (e) {
    console.error(e);
  }

  // Initialize all other components concurrently with dynamic imports.
  await Promise.allSettled([
    import('./components/ephemeral-experiences').then(module => module.initPageTotalExperiencesCaptured()),
    import('./components/navigation').then(module => module.initNavigation()),
    import('./components/yt_carousel').then(module => module.initYtCarousel())
  ]);

  // initBiasNumbers & initInfoBoxes modifies the DOM generated by initBiasGraphs,
  // so it must be called after the DOM is modified.

  setTimeout(async () => {
    await Promise.allSettled([
      import('./components/tech-giants-exposed').then(module => module.initTechGiantsExposed()),
      import('./components/bias-graphs').then(module => module.initBiasGraphs()),
      import('./components/vote-biases').then(module => module.initVoteBiases()),
      import('./components/elections-flipped').then(module => module.initElectionsFlipped()),
      import('./components/google-reshaping-elections').then(module => module.initGoogleReshapingElections()),
      import('./components/balanced-data-collection').then(module => module.initBalancedDataCollection()),
      import('./components/footer').then(module => module.initFooter()),
      import('./components/count_down_timer').then(module => module.initCountDowntimer()),
      import('./components/info-video').then(module => module.initInfoVideo())
    ]);

    await initInfoBoxes();
    await initCurrentBiasBoxes();
    await initAnalytics();

    if (debug === true) {
      console.log('Initialized');
    }
  }, 500);
}
