import './style.css';
import { setPeriodicTask } from '../../periodic-task';

const MAX_TIME_SECONDS = 5 * 60; // 5 minutes
const UPDATE_INTERVAL = 1000; // 1 second

// Calculate the seconds until the next 5 minute interval
function calculateRemainingTime () {
  const now = new Date();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const timePast = (minutes % 5) * 60 + seconds;
  return MAX_TIME_SECONDS - timePast;
}

export async function initCountDowntimer () {
  const $countdownMins = document.getElementById('countdown-minutes');
  const $countdownSeconds = document.getElementById('countdown-seconds');
  function update () {
    const remainingTime = calculateRemainingTime();
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const minutes_padded = minutes.toString().padStart(2, '0');
    const seconds_padded = seconds.toString().padStart(2, '0');
    $countdownMins.textContent = minutes_padded;
    $countdownSeconds.textContent = seconds_padded;
  }
  // await initUpdateLoop($countdownMins, $countdownSeconds);
  setPeriodicTask('countdown-timer', update, UPDATE_INTERVAL);
}
