import './style.css';
import htmlFrag from './sidenav.frag.html';
import { splashActivatedThisSession } from '../splash';

let gNavIsClosable = false;

/**
 *
 */
function getComponents () {
  return {
    dashboards: document.getElementById('dashboards'),
    navigation: document.getElementById('navigation'),
    // dashboards: document.getElementById('dashboards'),
    hamburger: document.getElementById('hamburger-icon'),
    arrow: document.querySelector('.hamburger-arrow')
  };
}

/**
 *
 */
function openNav () {
  const { hamburger, navigation } = getComponents();
  navigation.classList.add('active');
  hamburger.classList.remove('active');
}

/**
 *
 */
function closeNav () {
  const { hamburger, navigation, arrow } = getComponents();
  navigation.classList.remove('active');
  arrow.src = 'img/arrow-bar-right.svg';
  hamburger.classList.add('active');
}

/**
 *
 */
function toggleNavigation () {
  const { navigation } = getComponents();

  if (navigation.style.left === '0px') {
    closeNav();
  } else {
    openNav();
  }
}

export function handleWindowResize () {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 1024) {
    closeNav();
    gNavIsClosable = true;
  } else {
    openNav();
    gNavIsClosable = false;
  }
}

/**
 *
 */
export async function initNavigation () {
  const $dom = document.querySelector('#navigation');
  $dom.outerHTML = htmlFrag;
  const { hamburger, dashboards } = getComponents();
  hamburger.onclick = toggleNavigation;
  dashboards.onclick = () => {
    if (gNavIsClosable) {
      closeNav();
    }
  };
  function finalizeSetup () {
    window.onresize = () => {
      handleWindowResize();
    };
    handleWindowResize();
  }
  const val = splashActivatedThisSession();
  if (val) {
    setTimeout(() => {
      finalizeSetup();
    }, 3900);
    return;
  }
  finalizeSetup();
}
