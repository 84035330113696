import './style.css';
import './odometer-theme-train-station.css';
import Odometer from 'odometer';
import htmlFrag from './content.frag.html';
import { loadHtmlFragment } from '../../load-html-fragment';
import { getCachedEphemeralCounts } from './helper';
import { setCssRootVar } from '../../util';
import { setPeriodicTask } from '../../periodic-task.js';

// Improves performance by only doing costly resize operations after a delay.
const ODOMETER_RESIZE_DELAY = 250;

let g_odometer = null;
let g_last_call = null;
let g_last_odometer_value = null;
let g_odometer_animation_done = false;
let g_ephemeral_counts = null;
let g_last_update = null;

function updateOdometer (value) {
  // Odometer doesn't work too well so we have to put in a bunch of hacks
  // to make it work.
  // eslint-disable-next-line no-self-compare
  if (value === 0 || value === null || value !== value) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  value = Math.round(value);

  const isFirst = g_odometer === null;
  const el = document.getElementById('odometer');
  const now = new Date().getTime();

  if (isFirst) {
    g_odometer = new Odometer({
      el,
      value, // default value or the value you want to start from
      duration: 2000, // duration of the animation
      format: '(,ddd)' // format for the value
    });
    document.querySelector('.odometer').addEventListener('odometerdone', () => {
      g_odometer_animation_done = true;
    });
    g_last_odometer_value = value;
    setTimeout(() => {
      const $odometer = document.querySelector('#odometer');
      $odometer.classList.add('full-opacity');
    }, 30);
  }
  if (g_last_call === null) {
    g_last_call = now;
  }
  const delta = now - g_last_call;
  const { duration } = g_odometer.options;
  // g_odometer_animation_done doesn't always get triggered. Therefore have
  // a timeout + bool flag check to give us good expected performance plus
  // a fallback.
  const isTimeout = delta > duration * 1.5;
  if (isFirst || isTimeout || g_odometer_animation_done) {
    g_last_call = now;
    if (isFirst || (value >= g_last_odometer_value && value > 0)) {
      g_last_odometer_value = value;
      g_odometer_animation_done = false;
      let out = value;
      if (isFirst) {
        out = value + 1; // Hack to start the odometer.
      }
      g_odometer.update(out);
    }
  }
}

async function updateFetchEphemeralCounts () {
  g_ephemeral_counts = await getCachedEphemeralCounts();
}

function getMetricValue (counts) {
  return counts.interpolateShifted(new Date().getTime());
}

async function updateTask () {
  const data = g_ephemeral_counts;
  if (data !== null) {
    const value = getMetricValue(data);
    // eslint-disable-next-line no-self-compare, eqeqeq
    if (g_last_update === null || g_last_update != g_last_update) {
      g_last_update = value;
    }
    // eslint-disable-next-line no-self-compare, eqeqeq
    if (value >= g_last_update || g_last_update != g_last_update) {
      updateOdometer(value);
    }
  }
}

function updateOdometerPixels () {
  const $odeometerContainer = document.querySelector('#odometer');
  const width = $odeometerContainer.clientWidth;
  setCssRootVar('--odometer-current-pixels', width);
}

export async function initPageTotalExperiencesCaptured () {
  loadHtmlFragment('#total_experiences_captures', htmlFrag);
  let resizeTimeoutJob = null;
  const $odeometerContainer = document.querySelector('#odometer');
  const resizeObserver = new ResizeObserver(() => {
    clearTimeout(resizeTimeoutJob); // Cancel any previous job.
    resizeTimeoutJob = setTimeout(updateOdometerPixels, ODOMETER_RESIZE_DELAY);
  });
  resizeObserver.observe($odeometerContainer);
  updateOdometerPixels();
  await updateFetchEphemeralCounts();
  // setInterval(updateEphemeralCountsTask, 1000);
  // setInterval(updateTask, 100);
  setPeriodicTask('ephemeral-experiences-counts', updateFetchEphemeralCounts, 1000);
  setPeriodicTask('ephemeral-experiences-update', updateTask, 100);
}
