/**
 *
 */
export function loadHtmlFragment (querySelector, htmlFragmentString) {
  const target = document.querySelector(querySelector);

  if (!target) {
    console.error(`Could not find target element ${querySelector}`);
    return false;
  }

  // Generate a div and populate it with the string from the fragment.
  const div = document.createElement('div');
  div.innerHTML = htmlFragmentString;

  // Replace the target with the fragment
  target.innerHTML = div.outerHTML;

  return target;
}
