/**
 *
 */
export async function fetchJson (endpoint, map = undefined) {
  try {
    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const json = await response.json();
    const result = {};

    if (map === undefined) {
      return json;
    }

    for (const key of Object.keys(map)) {
      result[key] = json[map[key]];
    }

    return result;
  } catch (e) {
    console.error('Fetching failed:', e);
    return false;
  }
}
