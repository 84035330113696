import html from './georgia.frag.html';
import { createBarChart } from '../chart';

const CHART_ID = '#vote-georgia-bias';

/**
 *
 */
export async function initGeorgiaVote (containerId) {
  const $container = document.querySelector(containerId);
  $container.insertAdjacentHTML('beforeend', html);

  return createBarChart({
    platformName: 'georgia',
    height: 220,
    width: 475,
    default_range: '1W',
    selectors: {
      chart: `${CHART_ID} .canvasContainer`,
      canvas: `${CHART_ID} canvas`,
      range: `${CHART_ID} .time-range-selection .time-range-option`
    }
  });
}
