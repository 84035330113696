// TODO uncomment the commented lines when we get TikTok data
import html from './tiktok.frag.html';
// import { createBiasChart } from '../bias-graph';

export async function initTikTokGraph (containerId) {
  const $container = document.querySelector(containerId);
  $container.insertAdjacentHTML('beforeend', html);

  const CHART_ID = '#tiktok-bias';

  // if (availible === false) {
  const comingSoon = document.querySelector(`${CHART_ID} .coming-soon`);
  const biasNumber = document.querySelector(`${CHART_ID} .bias-number`);

  comingSoon.style.display = 'flex';
  biasNumber.style.display = 'none';

  return false;
  // }

  // const options = {
  //   platformName: 'tiktok',
  //   chart_height: 265,
  //   chart_width: 475,
  //   gradient_height: 150,
  //   default_range: '1Y',
  //   selectors: {
  //     disabledOverlay: `${CHART_ID} .chartDisabledOverlay`,
  //     chart: `${CHART_ID} .canvasContainer`,
  //     canvas: `${CHART_ID} canvas`,
  //     range: `${CHART_ID} .time-range-selection .time-range-option`
  //   }
  // };

  // await createBiasChart(options);
  // return true;
}
