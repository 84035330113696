const gPreviousIntersectionStates = {};
let gEnabled = false;
let gActiveSelector = null;
let gLastTriggerScrollPosition = 0;
let gLastScrollPosition = 0;

export function enableIntersectionObserver (on) {
  // Note that this will shut off the on exit event until re-enabled.
  gEnabled = on;
}

function isIntersecting (overlaySelector, selector) {
  const targetRect = document.querySelector(selector).getBoundingClientRect();
  const videoRect = document.querySelector(overlaySelector).getBoundingClientRect();
  // console.log(selector, videoRect, targetRect);
  // console.log('targetRect', targetRect.top, targetRect.bottom);
  // console.log('videoRect', videoRect.top, videoRect.bottom);
  const vidMidpoint = (videoRect.top + videoRect.bottom) / 2;
  // if midpoint is in the targetRect then it is intersecting.
  return targetRect.top <= vidMidpoint && vidMidpoint <= targetRect.bottom;
  // return videoRect.top < targetRect.bottom && videoRect.bottom >= targetRect.top;
}

function updateIntersectionState (overlaySelector, selector) {
  const isCurrentlyIntersecting = isIntersecting(overlaySelector, selector);
  // console.log('isCurrentlyIntersecting', isCurrentlyIntersecting, selector);
  const wasPreviouslyIntersecting = gPreviousIntersectionStates[selector] || false;
  gPreviousIntersectionStates[selector] = isCurrentlyIntersecting;

  if (isCurrentlyIntersecting && !wasPreviouslyIntersecting) {
    return 'ENTERED';
  } else if (!isCurrentlyIntersecting && wasPreviouslyIntersecting) {
    return 'EXITED';
  } else {
    return null;
  }
}

export function intersectionObserverInitLoop (overlaySelector, elements, onEnter, onExit) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function update () {
    if (!gEnabled) {
      return;
    }
    // There's some hysterisis so we stop firing events if the scroll position is within 20 pixels of the last scroll position.
    const currentScrollPosition = window.scrollY;
    if (gActiveSelector !== null) {
      const diffLastUpdate = Math.abs(currentScrollPosition - gLastScrollPosition);
      gLastScrollPosition = currentScrollPosition;
      if (diffLastUpdate < 20) {
        // Let the scroll settle.
        return;
      }
    }
    if (Math.abs(currentScrollPosition - gLastTriggerScrollPosition) < 20) {
      // Not at new trigger position yet.
      return;
    }
    gLastTriggerScrollPosition = currentScrollPosition;
    for (const element of elements) {
      if (gActiveSelector !== null) {
        // If a current element is active then only that is the one that can possibly be exited.
        if (gActiveSelector !== element) {
          continue;
        }
      }
      const intersectionState = updateIntersectionState(overlaySelector, element);
      if (intersectionState === null) {
        continue;
      }

      if (intersectionState === 'ENTERED') {
        gActiveSelector = element;
        await onEnter(element);
        return;
      }

      if (intersectionState === 'EXITED') {
        gActiveSelector = null;
        await onExit(element);
        return;
      }
    }
  }

  async function tick () {
    await update();
    setTimeout(tick, 200);
  }
  setTimeout(tick, 200);
}
