import './style.css';
import Chart from 'chart.js/auto';
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';
import htmlFrag from './content.frag.html';
import { fetchAllJsonCached } from '../../fetch-all-json';

Chart.register(getChartLabelPlugin());

const ENABLE_TOOLTIP = false;

const LABELS = ['Liberal', 'Conservative', 'Moderate', 'Other'];
const COLORS = ['#3230cc', '#c1250f', '#7a36c3', '#e4a710'];
const SCALES = {
  r: {
    pointLabels: {
      display: true,
      centerPointLabels: true,
      font: {
        size: 18
      }
    }
  }
};

const IDS = {
  component: 'balanced-data-collection-container',
  canvasContainer: 'data-collection-chart-container',
  canvas: 'data-collection-chart'
};

const DEFAULT_DATA = {
  agent_politics: {
    Liberal: 0,
    Conservative: 0,
    Moderate: 0,
    Undecided: 0
  }
};

/**
 *
 */
function getConfig (type, data) {
  const isPolarZones = type === 'polarAreaZones';
  const values = [
    data.Liberal,
    data.Conservative,
    data.Moderate,
    data.Undecided
  ];

  return {
    type: isPolarZones
      ? 'polarArea'
      : type,
    data: {
      label: LABELS,
      datasets: [{
        cutout: '20%',
        data: values,
        backgroundColor: COLORS
      }]
    },
    plugins: {
      labels: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 2
      }
    },
    options: {
      responsive: false,
      plugins: {
        labels: {
          fontSize: 18,
          fontColor: '#FFF',
          fontFamily: '"Inter", Helvetica',
          render: 'percentage',
          precision: 2
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: ENABLE_TOOLTIP,
          callbacks: {
            // eslint-disable-next-line func-names
            label: function (context) {
              const label = LABELS[context.dataIndex];
              const value = context.dataset.data[context.dataIndex];
              return `  ${value}% of our field agents identify as ${label}`;
            }
          }
        }
      },
      scales: isPolarZones
        ? SCALES
        : {}
    }
  };
}

/**
 *
 */
function getPieChart (type, data) {
  const config = getConfig(type, data);
  const chart = new Chart(IDS.canvas, config);

  return chart;
}

/**
 *
 */
export async function initBalancedDataCollection () {
  const $dom = document.getElementById(IDS.component);
  $dom.outerHTML = htmlFrag;

  const allData = await fetchAllJsonCached();
  const agent_politics = (allData || DEFAULT_DATA).agent_politics;
  const pieValue = new URLSearchParams(window.location.search).get('pie') || '1';

  switch (pieValue) {
    case '2':
      getPieChart('polarArea', agent_politics);
      break;
    case '3':
      getPieChart('polarAreaZones', agent_politics);
      break;
    case '4':
      getPieChart('pie', agent_politics);
      break;
    case '1':
    default:
      getPieChart('doughnut', agent_politics);
      break;
  }
}
