import './style.css';
import htmlFrag from './content.frag.html';
import { initNorthCarolinaVote } from './northCarolina';
import { initPennsylvaniaVote } from './pennsylvania';
import { initMichiganVote } from './michigan';
import { initGeorgiaVote } from './georgia';
import { initArizonaVote } from './arizona';
import { initWisconsinVote } from './wisconsin';
import { initNevadaVote } from './nevada';
import { initNationwideVote } from './nationwide';

const largeContainer = '#vote-graph-large';
const firstSmallContainer = '#vote-graph-small-first';
const secondSmallContainer = '#vote-graph-small-second';

export async function initVoteBiases () {
  const $dom = document.querySelector('#vote-biases');
  $dom.innerHTML = htmlFrag;

  const observerOptions = {
    root: null, // Use the viewport as the container
    rootMargin: '800px', // Trigger when the element is 100px from the viewport
    threshold: 0.1 // Trigger when 10% of the element is visible
  };

  const observer = new IntersectionObserver(async (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        await initVoteGraphs();
        observer.unobserve(entry.target);
      } else {
        console.warn('IntersectionObserver in vote-biases is firing on non-intersecting entries');
      }
    }
  }, observerOptions);

  observer.observe($dom);
}

/**
 *
 */
function delayStart (initializer, containerId, timeout) {
  return new Promise(resolve => {
    setTimeout(async () => {
      const result = await initializer(containerId);
      resolve(result);
    }, timeout);
  });
}

/**
 *
 */
async function initVoteGraphs () {
  await Promise.allSettled([
    delayStart(initNationwideVote, largeContainer, 25),
    delayStart(initPennsylvaniaVote, largeContainer, 50),
    delayStart(initNevadaVote, firstSmallContainer, 75),
    delayStart(initMichiganVote, firstSmallContainer, 100),
    delayStart(initNorthCarolinaVote, firstSmallContainer, 125),
    delayStart(initArizonaVote, secondSmallContainer, 150),
    delayStart(initGeorgiaVote, secondSmallContainer, 175),
    delayStart(initWisconsinVote, secondSmallContainer, 200)
  ]);

  return true;
}
