import './style.css';
import htmlFrag from './google-reshaping.frag.html';
import Chart from 'chart.js/auto';
import { ChoroplethController, GeoFeature, ColorScale, ProjectionScale, topojson } from 'chartjs-chart-geo';
import { fetchAllJsonCached } from '../../fetch-all-json';
import TOPOLOGY_US_STATES_JSON from './states-10m.json';
import { COLOR_STOPS_STATE_BIAS } from './political-map-data';

Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);

const COLOR_STOPS_REALTIME_BIAS = [
  [0.00, 'rgba(0,0,128,.7)'],
  [0.20, 'rgba(0,0,255,.7)'],
  [0.46, 'rgba(255,255,255, 0.2)'],
  [0.50, 'rgba(255,255,255, 0.0)'],
  [0.54, 'rgba(255,255,255, 0.2)'],
  [0.90, 'rgba(255,0,0,.7)'],
  [1.00, 'rgba(128,0,0,.7)']
];

// const TOPOLOGY_US_STATES_JSON = 'https://unpkg.com/us-atlas/states-10m.json';
const OFFSCREEN_CANVAS_ID = 'ujhE2gyC2ggrKa5k'; // Hidden canvas for gradient generation.
const OFFSCREEN_CANVAS_ID_2 = 'ujhE2gyC2ggrKa5k2'; // Hidden canvas for gradient generation.

const ABBREVIATED_STATE_NAMES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'District of Columbia': 'DC',
  'American Samoa': 'AS',
  Guam: 'GU',
  'Northern Mariana Islands': 'MP',
  'Puerto Rico': 'PR',
  'Virgin Islands': 'VI'
};

/*
function normalizeGradient (colorArray, min, max) {
  // Normalize the color from min, max to 0, 1
  // return colorArray.map((c) => (c - min) / (max - min));
  // adjust the first column which will be between min and max to output
  // values between 0 and 1
  colorArray = colorArray.map((c) => c.slice());
  const range = max - min;
  for (let i = 0; i < colorArray.length; ++i) {
    colorArray[i][0] = (colorArray[i][0] - min) / range;
  }
  return colorArray;
}
*/

class ColorGradientContext {
  constructor (canvasId, colorStops) {
    // Create an off-screen canvas
    const html = `<canvas id="${canvasId}" width="256" height="1" style="display:none;"></canvas>`;
    document.body.insertAdjacentHTML('beforeend', html);

    this.offscreenCanvas = document.getElementById(canvasId);
    this.offscreenContext = this.offscreenCanvas.getContext('2d', { willReadFrequently: true });

    // Create and set up the gradient
    this.gradient = this.offscreenContext.createLinearGradient(0, 0, this.offscreenCanvas.width, 0);
    // colorStops = normalizeGradient(colorStops, -0.4, 0.4);
    for (let i = 0; i < colorStops.length; ++i) {
      const [t, v] = colorStops[i];
      this.gradient.addColorStop(t, v); // Blue at the end
    }
    // Apply the gradient as fill style and fill the canvas
    this.offscreenContext.fillStyle = this.gradient;
    this.offscreenContext.fillRect(0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
  }

  getColorAt (t) {
    // Ensure that t is within the range [0, 1]
    const range = Math.max(0, Math.min(1, t));
    // Map t to the width of the off-screen canvas
    const x = Math.round(range * (this.offscreenCanvas.width - 1));
    // Get the color data at that point
    const data = this.offscreenContext.getImageData(x, 0, 1, 1).data;
    return `rgb(${data[0]},${data[1]},${data[2]})`;
  }
}

/**
 *
 */
function getStateBiasValue (stateData, stateName) {
  const ignored = {
    'Commonwealth of the Northern Mariana Islands': true,
    'United States Virgin Islands': true,
    'District of Columbia': true,
    'American Samoa': true,
    Guam: true,
    'Puerto Rico': true
  };
  if (ignored[stateName]) {
    return 0;
  }
  try {
    const stateCode = ABBREVIATED_STATE_NAMES[stateName];
    const data = stateData[stateCode];
    return data.avg_bias_rating;
  } catch (error) {
    console.warn(`Error getting value for state: ${stateName}`, error);
    return 0;
  }
}

/**
 *
 */
async function populateUsStateMap (id, stateData, gradient) {
  const context2d = document.getElementById(id).getContext('2d');

  try {
    const nation = topojson.feature(TOPOLOGY_US_STATES_JSON, TOPOLOGY_US_STATES_JSON.objects.nation).features[0];
    const states = topojson.feature(TOPOLOGY_US_STATES_JSON, TOPOLOGY_US_STATES_JSON.objects.states).features;
    const names = states.map((d) => d.properties.name);
    const stateBiases = states.map((d) => ({
      feature: d, // value: Math.random() * 10
      value: getStateBiasValue(stateData, d.properties.name)
    }));

    const data = {
      type: 'choropleth',
      data: {
        labels: names,
        datasets: [{
          label: 'States',
          outline: nation,
          showOutline: true,
          borderWidth: 1,
          data: stateBiases,
          borderColor: 'rgba(255, 255, 255, 0.5)'
        }]
      },
      options: {
        respsonsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          projection: {
            axis: 'x',
            projection: 'albersUsa'
          },
          color: {
            axis: 'x',
            min: -0.4,
            max: 0.4,
            // quantize: 5,
            interpolate: n => gradient.getColorAt(n), // Choose your preferred color scheme
            legend: {
              // position: 'bottom-right'
              // align: 'bottom'
              display: false
            }
          }
        }
      }
    };

    return new Chart(context2d, data);
  } catch (error) {
    console.error('Error fetching and processing data:', error);
    return false;
  }
}

/**
 *
 */
export async function initGoogleReshapingElections () {
  const $dom = document.querySelector('#google-reshaping-elections');
  $dom.outerHTML = htmlFrag;

  const stateData = (await fetchAllJsonCached()).state_bias_summaries.state_bias_summaries;
  const gradient = new ColorGradientContext(OFFSCREEN_CANVAS_ID, COLOR_STOPS_REALTIME_BIAS);
  const gradient2 = new ColorGradientContext(OFFSCREEN_CANVAS_ID_2, COLOR_STOPS_STATE_BIAS);

  const promises = [
    populateUsStateMap('content-bias-map-canvas', stateData, gradient),
    populateUsStateMap('usa-political-landscape-map-canvas', stateData, gradient2)
  ];
  await Promise.allSettled(promises);
}
