const gDeleters = new Map();

export function clearPeriodicTask (name) {
  const deleter = gDeleters.get(name);
  if (!deleter) {
    console.error('Periodic Task not found:', name);
    return;
  }
  deleter();
  gDeleters.delete(name);
}

export function setPeriodicTask (name, callback, interval, debug = false) {
  if (gDeleters.has(name)) {
    console.error('Periodic Task already running:', name);
    return;
  }
  // if the browser supports periodic background sync, use it, otherwise
  // use setInterval.
  if ('periodicSync' in navigator) {
    navigator.serviceWorker.ready.then(async (registration) => {
      try {
        await registration.periodicSync.register(name, {
          minInterval: interval
        });
        const jobId = navigator.serviceWorker.addEventListener('periodicsync', (event) => {
          if (event.tag === name) {
            callback();
          }
        });
        gDeleters.set(name, () => {
          navigator.serviceWorker.removeEventListener('periodicsync', jobId);
        });
      } catch (error) {
        console.error('Periodic Sync could not be registered!', error);
        const jobId = setInterval(callback, interval);
        gDeleters.set(name, () => {
          clearInterval(jobId);
        });
      }
    });
  } else {
    if (debug === true) {
      console.warn('Periodic Background Sync is not supported by this browser. Using setInterval instead.');
    }
    const jobId = setInterval(callback, interval);
    gDeleters.set(name, () => {
      clearInterval(jobId);
    });
  }
}
