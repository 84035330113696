import { Mutex } from 'async-mutex';

const SELECTOR = '#info-video video';
const SELECTOR_VID_CONTAINER = '#info-video';

// const mutex = withTimeout(new Mutex(), 10, new Error('video state changed timed out'));
const gMutex = new Mutex();
const gVideoEndStatus = {}; // New map to store video end status
let gActive = false;

async function sleep (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function updateVideoLink (vidsrc) {
  const videoElement = document.querySelector('#info-video video');
  const isWebM = vidsrc.endsWith('.webm');
  const isMp4 = vidsrc.endsWith('.mp4');
  // assert isWebM || isMp4
  if (!isWebM && !isMp4) {
    throw new Error('invalid video source:', vidsrc);
  }
  videoElement.src = vidsrc;
  if (isWebM) {
    videoElement.type = 'video/webm';
  } else {
    videoElement.type = 'video/mp4';
  }
}

export async function activateVideo (vidsrc, options = { autostart: undefined }) {
  // If the video has already ended, do not activate it again
  if (gVideoEndStatus[vidsrc]) {
    return;
  }
  let { autostart } = options;
  if (autostart === undefined) {
    autostart = false;
  }
  await gMutex.runExclusive(async () => {
    if (gActive) {
      console.warn('video already active');
      return;
    }
    gActive = true;
    const videoElement = document.querySelector(SELECTOR);
    updateVideoLink(vidsrc);

    videoElement.addEventListener('ended', async () => {
      console.log('video ended');
      gVideoEndStatus[vidsrc] = true;
      await deactivateVideo();
    });

    if (autostart) {
      videoElement.muted = false;
      const vidContainer = document.querySelector(SELECTOR_VID_CONTAINER);
      try {
        await videoElement.play();
        vidContainer.classList.add('active');
        videoElement.style.pointerEvents = 'auto';
        videoElement.volume = 1;
      } catch (e) {
        console.error('play error', e);
      }
    }
  });
}

export async function deactivateVideo () {
  console.log('waiting for deactivateVideo');
  await gMutex.runExclusive(async () => {
    console.log('deactivateVideo');
    if (!gActive) {
      console.warn('video already inactive');
      return;
    }
    gActive = false;
    const videoElement = document.querySelector(SELECTOR);
    const vidContainer = document.querySelector(SELECTOR_VID_CONTAINER);
    vidContainer.classList.remove('active');

    videoElement.volume = 1;
    const fadeAudio = setInterval(() => {
      if (videoElement.volume > 0.05) {
        videoElement.volume -= 0.05;
      } else {
        videoElement.volume = 0;
        clearInterval(fadeAudio);
      }
    }, 30);

    await sleep(1000);
    videoElement.style.pointerEvents = 'none';
    videoElement.pause();
    videoElement.currentTime = 0;
  });
}
